@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
}

#header {
    height: 100%;
    text-align: center;
    padding: 5px 0 ;
}

.link {
    width: 300px;
    display: block;
    margin: 10px auto;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 1px 10px lightgray;
    transition: all .5s;
    border: 3px solid transparent;
}

.link:hover {
    border: 3px solid purple;
}

.link a{
    display: flex;
    align-content: center;
    justify-content: flex-start;
    margin: 0 10px;
}

.link img {
    margin: 0 10px;
}

#text-position {
    display: flex;
    align-items: center;
}

#header-content a{
    text-decoration: none;
    color: black;
    transition: all .1s;
}

#header-content img {
    height: 30px;
}

#hero-content {
    height: calc(40vh - 60px);
    display: grid;
    justify-content: center;
    align-content: center;
}

#hero-grid h1 {
    font-size: 50px;
    line-height: 46px;
}

#hero-grid p {
    text-align: center;
    padding: 10px;
}

.purple {
    color: purple;
}

@media (min-width:1024px) {
    #header {
        box-shadow: 0 1px 10px lightgray;
    }
    
    #header-content {
        padding: 5px 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    
    #links {
        display: flex;
    }
    
    .link {
        width: 175px;
        margin-left: 10px;
    }
    
    #hero-content {
        height: calc(100vh - 90px);
    }
}